require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/landing/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var item, fileCity;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.layoutData['isDesktopBrowser'] = (await ( async function (kind) { return BackendlessUI.DeviceAPI.isRunningEnv(kind) })('DESKTOP_BROWSER'));
  ___arguments.context.layoutData['accordKrok'] = 0;
  fileCity = (JSON.parse((await (async function(fileURL) {
  	// async function fetchTextFile(fileURL) {
  	  try {
  	    const response = await fetch(fileURL);
  	
  	    if (!response.ok) {
  	      throw new Error('Błąd pobierania pliku.');
  	    }
  	
  	    const data = await response.text();
  	    return data;
  	  } catch (error) {
  	    throw error;
  	  }
  	// }

  })('json/data.json'))));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b4fac785a973bcbfd1c9e2ecf54a6246', fileCity);
  ___arguments.context.layoutData['repeaterKroki'] = (await Promise.all(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('b4fac785a973bcbfd1c9e2ecf54a6246')).map(async item => {; return ({ [`label`]: (getObjectProperty(item, 'tytul')),[`value`]: (getObjectProperty(item, 'krok')) });})));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/landing/components/07dd74ae934bd282f871a6ea3a986a17/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.layoutData, 'isDesktopBrowser'))) {
    if ((getObjectProperty(___arguments.context.dataModel, 'krok')) != (getObjectProperty(___arguments.context.layoutData, 'accordKrok'))) {
      ___arguments.context.layoutData['accordKrok'] = (getObjectProperty(___arguments.context.dataModel, 'krok'));
    } else {
      ___arguments.context.layoutData['accordKrok'] = 0;
    }
  }

  },
  /* handler:onClick */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData[(getObjectProperty(___arguments.context.dataModel, 'krok'))] = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('07dd74ae934bd282f871a6ea3a986a17'));

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./pages/landing/components/727577be046c9bf60fbf4dba58e193b5/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ({ [`backgroundImage`]: ['url(img/',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('07dd74ae934bd282f871a6ea3a986a17'), 'image')),')'].join('') })

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/landing/components/cb4b514300ba49755ad9e9b53647a52e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('07dd74ae934bd282f871a6ea3a986a17'), 'tytul'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./layouts/StickyHeader/components/766cb6880421b7a70d97ba56ab38d299/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var nowTime;


  nowTime = (new Date((new Date())).getHours());
  if ((Number(nowTime)) >= 15) {
    (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('tel:+48571024264', false, undefined);
  } else {
    (function (message) { alert(message) })('wyślij SMS lub zadzwoń po godzinie 15:00');
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./layouts/StickyHeader/components/3dfb5b55ee18b47d74c6118caa29356d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('mailto:projekt@zjazdnadzialke.pl', false, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./layouts/StickyHeader/components/0f98679dd871776539f499c54ddeaf51/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.layoutData, 'isDesktopBrowser'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./layouts/StickyHeader/components/dab5a2328c637d4dac55c7fe08416f65/bundle.js', [], () => ({
  /* content */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.layoutData, 'repeaterKroki'))

  },
  /* handler:onDynamicItemsAssignment */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.layoutData, 'isDesktopBrowser')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./layouts/StickyHeader/components/55af3af992937b9c8c046a8559c5f4a5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedKrok, marginTop;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  marginTop = 0;
  await new Promise(r => setTimeout(r, 100 || 0));
  ___arguments.context.layoutData['repeaterMarginTop'] = marginTop;
  await new Promise(r => setTimeout(r, 100 || 0));
  selectedKrok = (getObjectProperty(___arguments.context.dataModel, 'value'));
  await new Promise(r => setTimeout(r, 100 || 0));
  (function (component) { component.el.scrollIntoView() })((getObjectProperty(___arguments.context.pageData, selectedKrok)));
  await new Promise(r => setTimeout(r, 100 || 0));
  if (selectedKrok.indexOf('4') !== -1) {
    marginTop = 0;
  } else {
    marginTop = 150;
  }
  await new Promise(r => setTimeout(r, 100 || 0));
  ___arguments.context.layoutData['repeaterMarginTop'] = marginTop;

  },
  /* handler:onClick */
  /* handler:onScroll */
  ['onScroll'](___arguments) {
    var marginTop, selectedKrok;


  marginTop = 0;
  ___arguments.context.layoutData['repeaterMarginTop'] = marginTop;

  },
  /* handler:onScroll */
  /* content */
}))

define('./layouts/StickyHeader/components/9ce9a7150095b3fccecd174ed054be8a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ({ [`marginTop`]: (getObjectProperty(___arguments.context.layoutData, 'repeaterMarginTop')) })

  },
  /* handler:onStyleAssignment */
  /* content */
}))

